
function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
            end = dc.length;
        }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
}

function setCookie(name, value, persist) {
    const ds = window.location.hostname.split('.');

    var domain = '';
    if(ds.length <= 2 || ds.length == 4) {
        domain = ds.join('.');
    }
    else {
        domain = ds.slice(-2).join('.');
    }
    console.log("Using domain: " + domain);

    var cookie = name + '=' + value + '; Path=/; Domain=' + domain + ';';
    if (persist) {
        cookie += ' SameSite=Lax; Max-Age=15552000;';
    }

    document.cookie = cookie;
}

function rejectCookies() {
    setCookie('cookieConsent', 'False', false);
    closePopup();
}

function closePopup() {
    document.getElementById("cookiePopUp").style.visibility = 'hidden';
}


function enableAllCookies() {
    setCookie('cookieConsent', 'True', true);
    closePopup();
    updateConsentSettings();
}

function updateConsentSettings() {
    var myCookie = getCookie("cookieConsent");

    if (myCookie == "True") {
        //console.log("updating GTM consent");

        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }

        gtag('consent', 'update', {
            ad_storage: 'granted',
            analytics_storage: 'granted',
            ad_user_data: 'granted',
            ad_personalization: 'granted',
        });

        window.dataLayer.push({
            event: "interaction",
            target: "cookie_accept",
            action: "cookie_accept",
            interactionType: true
        });

        initPixels();
        
        const uid = getCookie("userId");
        if (uid != null) {
            setCookie('userId', uid, true);
        }
    };
}

function initCookiePopup(d = true) {
    document.getElementById("acceptAllCookies").addEventListener("click", enableAllCookies, false);
    document.getElementById("rejectCookies").addEventListener("click", rejectCookies, false);
    document.getElementById("closeCookieBanner").addEventListener("click", closePopup, false);
    
    var myCookie = getCookie("cookieConsent");
    if (myCookie == null) {
        try {
            if (d) {
                document.getElementById("cookiePopUp").style.visibility = 'visible';
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    updateConsentSettings();
}



function initGTM(consentSettings = {
    ad_storage: 'denied',
    analytics_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    wait_for_update: 500
}) {
    (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-53NDJ6J');

    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    window.gtag = window.gtag || gtag;

    gtag('consent', 'default', consentSettings);
    
    //console.log("init GTM");
}


function initPixels() {
    setTimeout(function(){
        // Facebook Pixel Code
            !function (f, b, e, v, n, t, s) {
                if (f.fbq) return; n = f.fbq = function () {
                    n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                n.queue = []; t = b.createElement(e); t.async = !0;
                t.src = v; s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s)
            }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
            window.fbq('init', '292558015894484');
            window.fbq('track', 'PageView');
        
        // TikTok Pixel Code Start
            !function (w, d, t) {
                w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                ttq.load('C5FHQV7GE0M3SF4IUJ5G');
                ttq.page();
            }(window, document, 'ttq');
        
        // Snap Pixel Code
            (function (e, t, n) {
                if (e.snaptr) 
                    return; 
                var a = e.snaptr = function () { a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments) };
                a.queue = []; 
                var s = 'script'; 
                const r = t.createElement(s); 
                r.async = !0;
                r.src = n; 
                var u = t.getElementsByTagName(s)[0];
                u.parentNode.insertBefore(r, u);
            })(window, document, 'https://sc-static.net/scevent.min.js');
        
            snaptr('init', '3aaf3c46-c49e-43a6-8cd5-07ab09d76b33', {});
            snaptr('track', 'PAGE_VIEW');
        
        // Meta
            const fbq = window.fbq;
            const ttq = window.ttq;
            const dataLayer = window.dataLayer;
        
            function trackEvent(ev, cat) {
                fbq("trackCustom", ev, {eventCategory: cat});
                
                dataLayer.push({
                    "event": ev,
                    "eventCategory": cat
                });
                
                if(ev == "Page Loaded")
                    ttq.track("Browse");
            }
            window.trackEvent = trackEvent;
        
            window.trackEvent("Page Loaded", "LandingPage");
    }, 100);
}
